/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

const DocumentationIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M4.6 21.46A1.92 1.92 0 0 1 3.04 20C3 19.82 3 19.53 3 12c0-8.49 0-7.94.13-8.29.17-.43.56-.84.99-1.03.35-.16-.02-.15 4.39-.16h3.97l2.84 2.83 2.84 2.84v2.17l-.3-.07c-.38-.1-.85-.17-1.26-.2h-.3v-.96h-4.76V4.4H4.88v15.2h5.4l.08.18c.25.51.63 1.04 1.1 1.55l.16.15H8.18c-1.89 0-3.5-.01-3.57-.02z" />
      <path d="M6.79 12.94V12h4.82l-.22.24c-.45.49-.78.97-1.03 1.47l-.09.18H6.8Zm0 3.8v-.94h1.45c1.38 0 1.46 0 1.45.04a7.14 7.14 0 0 0 0 1.8c.01.04-.07.05-1.45.05H6.8zm9.15 4.73a4.77 4.77 0 0 1-3.47-7.55 4.82 4.82 0 0 1 4.8-1.81 4.77 4.77 0 0 1 3.5 3.1c.17.52.23.92.23 1.53a4.3 4.3 0 0 1-.23 1.54 4.84 4.84 0 0 1-2.96 2.96 5.3 5.3 0 0 1-1.87.23zm.59-1.92a.64.64 0 0 0 .34-.77.64.64 0 0 0-.3-.36.49.49 0 0 0-.29-.07c-.19 0-.3.04-.43.16-.34.3-.23.9.2 1.05.14.05.35.04.48-.01zm.18-1.93c0-.37.08-.5.61-1.04.42-.44.48-.53.55-.83.03-.13.03-.24.02-.42a1.3 1.3 0 0 0-.42-.99c-.3-.3-.6-.42-1.07-.44a1.6 1.6 0 0 0-.88.17 1.2 1.2 0 0 0-.43.32c-.17.17-.23.25-.32.43l-.1.24c0 .03.4.22.74.36l.08-.15c.08-.18.27-.4.41-.47.3-.14.72-.1.92.1.14.12.18.23.19.47.02.32-.02.39-.52.9-.22.22-.43.45-.47.52-.13.2-.17.37-.17.71v.3h.86z" />
    </SvgIcon>
  );
};

export default DocumentationIcon;

/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

const UndrawShareLinkIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#a)" transform="matrix(.13 0 0 .13 .43 4.16)">
        <path fill="#f2f2f2" d="M61.65 114.95a.69.69 0 0 1-.66-.42.68.68 0 0 1 .17-.76l.04-.19-.02-.04a1.83 1.83 0 0 0-3.38.01c-.56 1.33-1.26 2.66-1.44 4.07a5.4 5.4 0 0 0 .1 1.86 21.68 21.68 0 0 1-1.55-13.25c.7-3.4 2.2-6.6 4.37-9.32a5.85 5.85 0 0 0 2.44-2.52c.19-.38.33-.8.4-1.22a5.5 5.5 0 0 1-.36-1.9c-.16-.25-.46-.37-.64-.62-.9-1.21-2.13-1-2.78.65-1.38.7-1.4 1.85-.55 2.96.54.7.62 1.66 1.09 2.41l-.15.19a22.12 22.12 0 0 0-2.31 3.65 9.17 9.17 0 0 0-.55-4.27c-.53-1.26-1.52-2.33-2.39-3.43-1.04-1.31-3.18-.74-3.37.93v.05l.38.23a.93.93 0 0 1-.38 1.68h-.01c.04.47.12.93.24 1.38-1.12 4.3 1.3 5.87 4.73 5.94l.22.12a21.36 21.36 0 0 0-1.2 9.17v-.04a5.65 5.65 0 0 0-1.94-3.27c-1.49-1.22-3.6-1.67-5.2-2.65a1.07 1.07 0 0 0-1.63 1.04v.04a6.22 6.22 0 0 1 1.08.57.92.92 0 0 1-.37 1.68h-.06c.4.98.98 1.88 1.7 2.66.7 3.76 3.7 4.11 6.9 3.02.35 1.52.86 3 1.52 4.42h5.42l.06-.18c-.5.03-1 0-1.5-.09l1.2-1.48.03-.03.62-.75a9 9 0 0 0-.27-2.3z" />
        <path fill="#a0616a" d="m19.9 106.76 1.5 1.58 6.95-5.14-2.21-2.33z" />
        <path fill="#2f2e41" d="m22.35 107.63-1.7-1.59-.41-.39-4.32 3.44 1.12 1.19 1.32-.85-.6 1.6 2.97 3.14a.97.97 0 0 0 1.68-.81l-.65-4.15 1.14-1.07zm30.46-58.37-.47 5.17s-.83 3.9-.25 3.58c.57-.31-.46 1.1-.19 2.34.27 1.23-2.31 10.42-1.14 9.47 1.17-.95-.68 5.63-1.85 8.87a348.97 348.97 0 0 1-2.74 7.46l-.19.51v.01c-1.52 3.98-2.82 7.1-3.33 7.62-.27.27-4.56 3.36-8.89 6.46-4.53 3.25-9.1 6.5-9.1 6.5l-4.28-4.47.3-.09 1.87-.55c3.6-1.05-.97-2.7 2.3-3.87a7.52 7.52 0 0 0 1.67-.73c.8-.79 1.9-2.67 2.41-1.88s2.84-5.08 3.52-5.66c.36-.3 1.53-1.77 2.54-3.06.87-1.1 1.61-2.09 1.61-2.09l.04-1.36.13-5.08.05-2.14A512.6 512.6 0 0 1 36.9 72c0-.87 0-.19.7-.72l.29-.26c.15-.13.28-.28.4-.44.06-.1-.26.04-.68-.04a1.24 1.24 0 0 1-.6-.3c-.87-.79.07-3.2.07-3.2-1.98-3.95-1.84-7.2-.9-9.7a12.62 12.62 0 0 1 4.97-6.07l.06-.33.67-3.61a7.7 7.7 0 0 1 4.82-.87c1.25.17 2.45.56 3.56 1.14.91.46 1.76 1.02 2.54 1.67z" />
        <path fill="#a0616a" d="m34.11 114.81 2.17.2 1.83-8.44-3.2-.3z" />
        <path fill="#2f2e41" d="m40.36 118.12-3.22-2.7.14-1.54-.75-.03-2.32-.07-.57-.02-.97 5.42 1.63.15.43-1.5.6 1.6.86.08 3.45.32h.1a.97.97 0 0 0 .62-1.71zm5.81-31.97c-.82 4.48-4.32 15-6.43 21.14-.96 2.81-1.63 4.71-1.63 4.71l-1.1-.34-1.86-.58-.67-.22-.82-.26s.1-5.01.1-9.85c.01-2.03 0-4.03-.04-5.62a15.9 15.9 0 0 0-.24-3.2c-.52-1.01.34-1.12 1.25-1.37.91-.25.2-.64-.15-1.45-.24-.55.23-1.28.56-1.69l.3-.33-.45-.15c.87-1.11 1.61-2.1 1.61-2.1l.04-1.35.13-5.08.05-2.14a372.8 372.8 0 0 1 3.98 3.09c2.95 2.32 5.16 4.19 5.47 4.78.14.27.1.98-.1 2.01z" />
        <path fill="#ffb6b6" d="M118.78 7.02a1.57 1.57 0 0 1 1.7 1.61l5.03 2.14-.2 2.93-6.56-3.68a1.56 1.56 0 0 1-1.33-1.35 1.5 1.5 0 0 1 1.36-1.65z" />
        <path fill="#3865a3" d="M152.94 25.07a4.59 4.59 0 0 0-.97-1.98 69.69 69.69 0 0 0-1.7-1.96c-1.11-1.2-1.83-1.84-2.16-1.9l-.48-.06c-.78-.1-1.35-.17-1.37-1 0-.12 0-.23.02-.35.03-.2.08-.4.13-.58.04-.11.06-.22.08-.33.02-.15.01-.26-.06-.32-.1-.08-.27-.07-.57.05l-.17.07-.06.03-.12.06c-.37.17-.94.2-1.59.13-1.9-.2-4.5-1.16-4.53-1.17l-16.28-6.31-.15.24-.36.58-.13.2-.14.22-.61 1-.31.5 5.26 3.95.57.43.5.37 10.03 7.56 3.75 2.47.83.55 3.56 2.34a4.6 4.6 0 0 0 7.03-4.8zM176.26 120H.75a.24.24 0 0 1-.17-.41c.04-.05.1-.08.17-.08h175.51a.24.24 0 1 1 0 .49z" />
        <path fill="#ffb6b6" d="m40.66 40.52.46 4.22-.27 5.53 12.3-1.45 1.15-8.3-1.47.01z" />
        <path fill="#3865a3" d="m51.12 26.88-4.63-3.65-2.43-.14-3.6 2.3-.88 4.5-2.1 5.05 3.07 13.65s.29.98-.47 1.1c-.75.12-.66 1.56-.66 1.56s.16 1.25-.57 1.36c-.74.11-2.68.11-.98 2.3 1.7 2.18 15.19-6.08 15.19-6.08l1.96-10.25s3.8-5.1-.3-8z" />
        <path fill="#a0616a" d="M30.92 57.87a2.22 2.22 0 0 1 .67-3.35l5.82-19.48 3.64 1.83-6.34 18.67a2.23 2.23 0 0 1-2.42 3.14 2.24 2.24 0 0 1-1.37-.81z" />
        <path fill="#3865a3" d="M41.3 25.21s-1.05-.55-1.76 1.84c-.57 1.91-5.19 13.3-5.25 16.06a1.75 1.75 0 0 0-1.39 1.63l5.72.77c1-.84 1.26-1.98.87-2.31l1.24-3.39 5.18-10.75-3.86-3.46z" />
        <path fill="#a0616a" d="M71.83 51.46a2.22 2.22 0 0 0-2.06-2.72L56.42 33.53 53.6 36.9 67.4 51a2.23 2.23 0 0 0 3.55 1.79c.45-.32.76-.8.88-1.33z" />
        <path fill="#3865a3" d="M48.32 26.5s.7-.95 2.38.9c1.34 1.48 10.44 9.74 11.7 12.21a1.76 1.76 0 0 1 1.95.88l-4.82 3.15c-1.26-.32-2-1.23-1.79-1.7l-2.58-2.52-9.33-7.46 1.98-4.79z" />
        <path fill="#f57b20" d="M98.11 68.26a34.05 34.05 0 0 0 34.1-34A34.05 34.05 0 0 0 98.11.24a34.05 34.05 0 0 0-34.1 34.01c0 18.78 15.27 34 34.1 34z" />
        <path fill="#000" d="M96.5 46.29a8.34 8.34 0 0 1-14.17-4.98 8.3 8.3 0 0 1 1.26-5.38l-1.83 1.87a8.3 8.3 0 0 0 5.89 14.18c2.2 0 4.33-.88 5.89-2.43l4.06-4.2.06-.27zm3.86-25.99a8.35 8.35 0 0 1 12.99 7.53 8.3 8.3 0 0 1-2.25 5.05l2.15-1.5a8.3 8.3 0 1 0-9.37-13.73l-4.78 3.38-.1.24z" opacity=".2" />
        <path fill="#fff" d="M87.65 53.02a9.07 9.07 0 0 1-8.37-5.58 9.01 9.01 0 0 1 1.97-9.84l7.68-7.83a9.06 9.06 0 0 1 12.82 0 .73.73 0 0 1-.52 1.24.73.73 0 0 1-.51-.21 7.6 7.6 0 0 0-10.75 0c-1.13 3.97-3.57 6.7-7.69 7.82a7.58 7.58 0 1 0 10.74 10.72c-.16-1.62.31-3.16 4.05-4.2a.73.73 0 1 1 1.06 1.02l-4.06 4.2a9 9 0 0 1-6.42 2.66z" />
        <path fill="#fff" d="M100.13 41.95a9.02 9.02 0 0 1-6.4-2.64.73.73 0 1 1 1.03-1.04 7.6 7.6 0 0 0 10.74 0c.98-5.85 4.94-6.67 8.4-8.37a7.59 7.59 0 1 0-10.75-10.72c-.96 2.77-1.9 5.56-4.76 4.75a.73.73 0 0 1-1.03-1.03l4.76-4.75a9.06 9.06 0 0 1 12.8 12.78l-8.38 8.38a9.01 9.01 0 0 1-6.41 2.64z" />
        <path fill="#3f3d56" d="M127.73 16.94a34.65 34.65 0 0 0-8.83-9.93 34.33 34.33 0 0 0-53.92 36.26 34.23 34.23 0 0 0 28.17 24.87A34.41 34.41 0 0 0 128.6 50a34.13 34.13 0 0 0-.87-33.05zM98.11 68.02a33.9 33.9 0 0 1-23.94-9.89 33.75 33.75 0 0 1-9.91-23.88 33.85 33.85 0 1 1 66.35 9.44 33.42 33.42 0 0 1-2.37 5.93 33.91 33.91 0 0 1-30.13 18.4z" />
        <path fill="#ffb6b6" d="m144.82 114.89-2.24-.02-.99-8.81 3.3.03z" />
        <path fill="#2f2e41" d="m145.41 113.86-.58-.04-2.39-.16-.77-.05-.01 1.6-3.58 2.41a1 1 0 0 0-.39 1.12 1 1 0 0 0 .95.7l4.45.04.78-1.57.28 1.58 1.68.02z" />
        <path fill="#ffb6b6" d="m168.39 113.26-2.14.66-3.61-8.1 3.16-.97z" />
        <path fill="#2f2e41" d="m168.64 112.1-.57.14-2.32.56-.75.18.47 1.53-2.68 3.38a1 1 0 0 0 .26 1.47 1 1 0 0 0 .82.1l4.26-1.3.26-1.74.76 1.43 1.6-.5zm-28.32-71.31-4.27 12.38-.14 1c-1.08 1.01.27 1.35-.23 1.62s-.85 1.02-.17 1.15c.69.14 2.89 48.43 5.8 50.3 2.9 1.88 4.67 3.24 4.13.5-.21-1.11.1-7.04.53-13.73.05-.76.84-1.56.16-2.3s.08-1.22.12-1.83c.62-8.74 1.32-17.36 1.32-17.36s4.53 15.51 5.7 14.31c1.18-1.2.72 1.76 1.08 2.64.3.74-.98 2.19.9 2.21 1.88.02 6.3 15.1 6.75 15.08.9-.03 3.39.06 4.87-.24.74-.15-2.52-11.57-5.23-21.42-.22-.79-1.4-.8-.64-2.33s-3.01-11.33-2.69-11.79c.86-1.2 1.19-1.73.34-1.8-.85-.08-1.1.38-1.14-.8s-.2-4.04-.2-4.04-.6-11.4-4.9-17.71l-.37-3.74z" />
        <path fill="#3865a3" d="m150.99 17.15-5.66.8-.35 4.1-2.8 4.36s-6.2 4.36-1.87 11.25c-1.65 5.93-3.29 11.62-4.85 15.42-1.22 7.53 9.69 8.25 22.16 5.59 0 0-.17-3.22-1.3-4.77-1.14-1.55-.52-1.89-.52-1.89l-.8-2.89c.46-4.18-1.05-4.55-1.77-6.49l2.16-20.03z" />
        <path fill="#ffb6b6" d="M123.8 52.17a1.56 1.56 0 0 1 1.98-1.26l3.28-4.36 2.8.89-5.14 5.48a1.55 1.55 0 0 1-2.2.78 1.49 1.49 0 0 1-.72-1.53z" />
        <path fill="#3865a3" d="m152.94 25.07-.26-.3a4.59 4.59 0 0 0-5.32-.91c-2.9 1.42-4.48 2.37-4.67 2.82l-.18.44-.16.4c-.22.52-.48.84-1.14.7a2.62 2.62 0 0 1-.62-.25c-.1-.04-.17-.09-.25-.13-.28-.16-.49-.27-.63-.17-.13.1-.14.36-.01.94.04.25.03.5-.02.74-.17.7-.42 1.38-.75 2.03-.51 1-1.08 1.97-1.7 2.9l-5.8 8.25-.39.55-.43.61-3.42 4.86.2.21.85.86.19.2.17.17.44.45.42.42 9.23-7.5 1.03-.84 1.85-1.5 3.78-3.08 7.16-6.6a4.54 4.54 0 0 0 1.47-3.25v-.12a4.5 4.5 0 0 0-1.04-2.9z" />
        <path fill="#ffb6b6" d="M147.59 17a6.32 6.32 0 1 0 .02-12.64 6.32 6.32 0 0 0-.02 12.64z" />
        <path fill="#2f2e41" d="M155.98 19.44a9.95 9.95 0 0 1 1.66 4.06c-.68.44-1.48.67-2.29.66l-.09-.32a9.11 9.11 0 0 0-1.04-2.33 5.6 5.6 0 0 1-.3 2.3c-.44-.2-.9-.31-1.37-.35-.38 0-.76.1-1.1.26l-1.03-3.4c0 2.18-3.2 4.08-6.7 3.8-.13-.07 1.14-3.65 1.02-3.74-.28-.18-.55-.38-.83-.58 1.15-1.76 2.39-3.65 2.18-3.93.01.35-1.24 2.03-2.6 3.62-.76-.55 3.54-9.57 1.41-10.3-.03.31-.5.31-1.07.25a5.8 5.8 0 0 0-.67-1.5c.04.47-.02.94-.16 1.39-.57 0-1.13.06-1.68.17-.13-.2-.27-.39-.43-.57.02.22.01.44-.02.67-.56.12-1.02.26-1.1.37-.43-.16.32-.89.04-1.26-.7-.95.34-2.32 1-3.3a5.03 5.03 0 0 1 6.42-1.49c.1-.33.58-.54 1.2-.69.27-.06.54-.08.81-.07 2.68.05 6.4 2.25 6.55 4.92 0 .27.03.56.05.87.19 2.99-.05 5.74 2 7.93.74.8.75 4.53.47 6.46-.74-.77-1.27-3.78-2.33-3.9z" />
        <path fill="#f57b20" d="M86.26 35.1a.73.73 0 1 0 0-1.46.73.73 0 0 0 0 1.46zm23.14-.48a.73.73 0 1 0 0-1.46.73.73 0 0 0 0 1.46z" />
        <path fill="#2f2e41" d="M39.86 13.31c-.56 1.38-2.13 4.1-2.34 5.58l.1-.1c.18.32.38.61.61.89a4.3 4.3 0 0 1 1.47-1c-.3.61-.48 1.28-.52 1.97.6 1.85 3.33 1.03 4.94.82 1.6 1.83 3.73 2.3 6.52 1.26.78.08 1.65.13 2.2-.33.54-.46.22-1.31.51-1.96 2.1-4.59.16-6.24-1.48-8.32-.36-.46-.36-1.14-.8-1.58-.61-.6-1.55-.8-2.4-.94a1.31 1.31 0 0 0 1.14-.57 1.17 1.17 0 0 0-.02-1.23c-.3-.5-.94-.8-1.56-.88a5.89 5.89 0 0 0-1.82.16c-.38.05-.75.17-1.1.35a.9.9 0 0 0-.46.9 1.16 1.16 0 0 0 .68.75 3 3 0 0 0 .78.21 6.12 6.12 0 0 0-3.58.63 6.5 6.5 0 0 0-2.87 3.39z" />
        <path fill="#a0616a" d="M47.34 21.05a4.94 4.94 0 1 0 .01-9.87 4.94 4.94 0 0 0 0 9.87z" />
        <path fill="#2f2e41" d="M42.59 13.48c.28.86 1.1 1.14 1.86 1.32l.72.15.28-.32a4.3 4.3 0 0 1 1.47-1c-.24.48-.4 1-.47 1.54.45.07.91.12 1.38.16a1.56 1.56 0 0 0 1.83-1.12c.42.69.92 1.33 1.6 1.6a1.94 1.94 0 0 0 2.2-.65c.4-.54.67-1.15.8-1.8.2-.77.3-1.65-.08-2.14-.3-.39-.83-.4-1.31-.36-1.07.08-2.12.27-3.15.57l-.09.2c.18-1.07-.2-2.09-.92-2.45-.47-.24-1.05-.2-1.61-.15-.72.03-1.43.17-2.1.4-.79.3-1.45.84-1.92 1.53-.5.75-.74 1.78-.5 2.52z" />
        <path fill="#2f2e41" d="M49.63 8.87h.04a.4.4 0 0 0 .06 0s.05-.02.02 0a.4.4 0 0 0 .19-.13c.12-.14.2-.3.27-.48.08-.23.2-.44.37-.61a.42.42 0 0 1 .67.1.9.9 0 0 1-.06.68A1.94 1.94 0 0 1 50 9.7c-.41.15-.86.16-1.28.04a.3.3 0 0 1-.14-.45.3.3 0 0 1 .13-.12l.7-.28a.3.3 0 0 1 .43.27.3.3 0 0 1-.19.29l-.7.28v-.56a1.22 1.22 0 0 0 .55.03 1.27 1.27 0 0 0 .49-.16l.04-.03a1.73 1.73 0 0 0 .33-.28l.11-.15a1.58 1.58 0 0 0 .23-.6c.02-.08 0 .03.02.06l.13.1a.17.17 0 0 0 .14-.02c.02-.03.02-.02 0 0a2.9 2.9 0 0 0-.2.37c-.08.2-.18.38-.3.55a.98.98 0 0 1-1.07.41.3.3 0 0 1-.17-.4.31.31 0 0 1 .4-.17z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h176v120H.5z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default UndrawShareLinkIcon;

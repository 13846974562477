import { useQuery } from '@tanstack/react-query';

import { getUserAvatar } from '../Services/axios/filesAxios';

const useUserPfp = (data, rest = {}) => {
  const { data: imgBlob, isFetching } = useQuery({
    queryKey: ['userPfp', data],
    queryFn: () => getUserAvatar(data),
    enabled: !!data,
    ...rest,
  });

  return [imgBlob || null, isFetching];
};

export default useUserPfp;

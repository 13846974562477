import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ContentLayout from '../shared/REDISIGNED/layouts/ContentLayout/ContentLayout';
import PageLayout from '../shared/REDISIGNED/layouts/PageLayout/PageLayout';
import { StyledFlex } from '../shared/styles/styled';
import CustomIndicatorArrow from "../shared/REDISIGNED/selectMenus/customComponents/indicators/CustomIndicatorArrow";
import CustomSelect from "../shared/REDISIGNED/selectMenus/CustomSelect";
import useAgents from "../Managers/AgentManager/hooks/useAgents";
import Spinner from "../shared/Spinner/Spinner";
import InputLabel from "../shared/REDISIGNED/controls/InputLabel/InputLabel";
import { useQuery } from "@tanstack/react-query";
import { WIDGETS_QUERY_KEYS } from "../Settings/Components/FrontOffice/constants/common";
import { getFilteredChatWidgets } from "../../Services/axios/widgetAxios";


const createWidgetDiv = (ivaAgentId, widgetId) => {
  const div = document.createElement('div');
  div.classList.add('simplyask-agent-widget');
  div.setAttribute('data-iva-agent-id', ivaAgentId);
  div.setAttribute('data-widget-id', widgetId);
  return div;
};

const DemoChat = () => {
  const [params, setSearchParams] = useSearchParams();

  const ivaAgentId = params.get('ivaAgentId');
  const widgetId = params.get('widgetId');

  const [agent, setAgent] = useState(null);
  const [widget, setWidget] = useState(null);

  const { agents, isAgentsLoading } = useAgents(
    { pageSize: 999 },
    {
      select: (res) => res?.content?.map((agent) => ({ label: agent.name, value: agent.agentId })),
    }
  );

  const { data: widgets, isLoading: isWidgetsLoading } = useQuery({
    queryKey: [WIDGETS_QUERY_KEYS.GET_CHAT_WIDGETS_ALL],
    queryFn: () => getFilteredChatWidgets('pageSize=999'),
    select: (res) => res?.content?.map((widget) => ({ label: widget.name, value: widget.widgetId })),
  });

  const handleChange = (key, value) => {
    params.set(key, value);
    setSearchParams(params);

    window.location.reload();
  }

  useEffect(() => {
    if (ivaAgentId) {
      setAgent(ivaAgentId);
    }
  }, [ivaAgentId])

  useEffect(() => {
    if (widgetId) {
      setWidget(widgetId);
    }
  }, [widgetId])

  useEffect(() => {
    if (agent && widget) {
      const widgetDiv = createWidgetDiv(agent, widget);
      document.body.appendChild(widgetDiv);

      const loadScript = (src) => {
        const script = document.createElement('script');
        script.src = src;
        document.body.appendChild(script);
      };

      const interval = setTimeout(() => {
        loadScript(`${import.meta.env.VITE_SIMPLYASK_CDN}/widget/${import.meta.env.VITE_CHAT_WIDGET_FILE_NAME}`);
      }, 500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [agent, widget]);

  return (
    <PageLayout>
      <ContentLayout>
        <h1>This is a Symphona customer's page where they want to add a widget.</h1>
        <StyledFlex direction="row" gap="25px">
          <StyledFlex width="300px" m="30px 0" position="relative">
            {isAgentsLoading && <Spinner fadeBgParent extraSmall />}
            <InputLabel label="Agent" size={15} mb={10} />
            <CustomSelect
              options={agents}
              name="agents"
              value={agents?.find((ag) => ag.value === agent)}
              onChange={({ value }) => handleChange('ivaAgentId', value)}
              placeholder="Select Agent..."
              components={{
                DropdownIndicator: CustomIndicatorArrow,
              }}
              form
              minHeight={40}
              menuPadding={0}
              isSearchable
              closeMenuOnSelect
              isDisabled={isAgentsLoading}
            />
          </StyledFlex>
          <StyledFlex width="300px" m="30px 0" position="relative">
            {isWidgetsLoading && <Spinner fadeBgParent extraSmall />}
            <InputLabel label="Widget" size={15} mb={10} />
            <CustomSelect
              options={widgets}
              name="widgets"
              value={widgets?.find((ag) => ag.value === widget)}
              onChange={({ value }) => handleChange('widgetId', value)}
              placeholder="Select Widget..."
              components={{
                DropdownIndicator: CustomIndicatorArrow,
              }}
              form
              minHeight={40}
              menuPadding={0}
              isSearchable
              closeMenuOnSelect
              isDisabled={isWidgetsLoading}
            />
          </StyledFlex>
        </StyledFlex>
        <StyledFlex direction="row" gap="20px">
          <StyledFlex width="25%">
            <Skeleton animation={false} variant="rectangular" height={400} />
          </StyledFlex>
          <StyledFlex width="75%">
            <Skeleton animation={false} variant="rectangular" height={50} sx={{ marginBottom: '20px' }} />
            <Skeleton animation={false} variant="rectangular" height={50} sx={{ marginBottom: '20px' }} />
            <Skeleton animation={false} variant="rectangular" height={50} sx={{ marginBottom: '20px' }} />
            <Skeleton animation={false} variant="rectangular" height={50} sx={{ marginBottom: '20px' }} />
            <Skeleton animation={false} variant="rectangular" height={50} sx={{ marginBottom: '20px' }} />
            <Skeleton animation={false} variant="rectangular" height={50} sx={{ marginBottom: '20px' }} />
          </StyledFlex>
        </StyledFlex>
      </ContentLayout>
    </PageLayout>
  );
};

export default DemoChat;

export const DEFAULT_PAGINATION = {
  PAGE_SIZE: 10,
  PAGE_NUMBER: 0,
};
export const TAGS_KEY = 'tags';
export const IS_UPDATING_ELEMENT = 'isUpdating';
export const INITIATE_API_KEY = 'initiateApi';

export const EXECUTION_FRAMEWORKS = {
  RPA: 'RPA',
  CUCUMBER: 'Cucumber'
}

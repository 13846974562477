export default Object.freeze({
  primary: '#2d3a47',
  secondary: '#f57b20',
  tertiary: '#FEF2E9',
  background: '#FBFBFB',
  black: '#000',
  white: '#fff',
  danger: '#ff5252',
  success: '#4BB543',
  statusResolved: '#5f9936',
  statusResolvedBackground: '#ebf2e6',
  statusAssigned: '#e7bb09',
  statusAssignedBackground: '#fcf7e0',
  statusOverdue: '#e03b24',
  statusOverdueBackground: '#fbe7e4',
  statusUnassigned: '#3865a3',
  statusUnassignedBackground: '#e6ecf4',
  charcoal: '#2D3A47',
  lighterColor: '#FEF2E9',
  information: '#808080',
  switchBg: '#CFD7DF',
});

/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

const SupportIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M3.8 20.29a1 1 0 0 1-.55-.32.83.83 0 0 1-.22-.38C3 19.5 3 18.83 3 12.33c0-6.9 0-7.16.05-7.28.2-.69.74-1.2 1.4-1.33.16-.03.93-.03 7.63-.02 7.19 0 7.45 0 7.58.04a1.93 1.93 0 0 1 1.27 1.2l.07.2v11.53l-.07.2c-.2.58-.63.99-1.23 1.18l-.18.05-6.37.01c-5.84.01-6.37.02-6.45.05-.27.1-.28.12-1.23 1.06-.67.66-.95.93-1.03.97a1 1 0 0 1-.63.1zm1.42-3.37.25-.22.3-.25c.25-.13-.23-.12 6.45-.12 6.7 0 6.21 0 6.48-.13a.96.96 0 0 0 .4-.4c.13-.23.13.1.13-4.9 0-4.5 0-4.58-.05-4.71a1 1 0 0 0-.62-.65l-.12-.05H5.59c-.37.08-.66.35-.78.72-.03.1-.03.6-.03 5.32 0 5.15 0 5.2.04 5.27.08.13.28.19.4.12z" />
      <path d="M11.78 14.47a.84.84 0 0 1-.4-.23.88.88 0 0 1 .25-1.44c.15-.07.17-.07.37-.07.21 0 .23 0 .37.07.2.1.35.25.44.43.06.12.06.16.07.34 0 .17 0 .22-.04.32a.89.89 0 0 1-1.06.58zm0-3.61a.9.9 0 0 1-.66-.73c-.03-.17-.03-1.9 0-2.07.11-.7.96-1 1.48-.51.13.11.2.21.25.36.03.11.04.19.04 1.19s0 1.08-.04 1.18a.9.9 0 0 1-1.07.58z" />
    </SvgIcon>
  );
};

export default SupportIcon;

export default Object.freeze({
  DEFAULT: '/',
  LOCKED_ACCOUNT: '/lockedAccount',
  CHAT: '/LiveChat',
  TICKETS: '/Tickets',
  TICKETS_FULLVIEW: '/Tickets/:ticketId',
  FALLOUT_TICKETS: '/falloutTickets',
  FALLOUT_TICKETS_FULL_VIEW: '/falloutTickets/:falloutTicketId',
  EXECUTION_HISTORY: '/executionHistory',
  BOA_DASHBOARD: '/boaDashboard',
  BOA_DASHBOARD_PROCESS_VIEW: '/boaDashboard/:workflowName',
  CONVERSE_DASHBOARD: '/converse/dashboard',
  PROCESS_HISTORY: '/processHistory',
  PROCESS_HISTORY_BULK_PREVIEW: '/processHistory/bulkPreview/:ticketId',
  FRONT_OFFICE: '/FrontOffice',
  REGISTER: '/register',

  TEST_DASHBOARD: '/test/dashboard',
  TEST_HISTORY: '/test/history',
  SETTINGS: '/Settings',
  SETTINGS_SERVICES: '/Settings/Agents',
  SETTINGS_ACCESS_MANAGER: '/Settings/AccessManagement',
  SETTINGS_ACCESS_MANAGER_USER_DETAILS: '/Settings/AccessManagement/users/:id',
  SETTINGS_ACCESS_MANAGER_USER_CHANGE_PASSWORD: '/Settings/AccessManagement/users/:id/changePassword',
  SETTINGS_ACCESS_MANAGER_EDIT_USER: '/Settings/AccessManagement/users/:id/edit',
  SETTINGS_ACCESS_MANAGER_USERS: '/Settings/AccessManagement/users',
  SETTINGS_ACCESS_MANAGER_PERMISSION_GROUPS: '/Settings/AccessManagement/permissionGroups',
  SETTINGS_ACCESS_MANAGER_PERMISSION_GROUP_DETAILS: '/Settings/AccessManagement/permissionGroups/:id',
  SETTINGS_CHANNELS: '/Settings/Channels',
  SETTINGS_USER_MANAGEMENT: '/Settings/UserManagement',
  SETTINGS_PAGE_PERMISSIONS: '/Settings/PagePermissions',
  SETTINGS_PERMISSION_GROUPS: '/Settings/PermissionGroups',
  SETTINGS_USER_GROUPS: '/Settings/AccessManagement/userGroups',
  SETTINGS_USER_GROUPS_SINGLE_ITEM: '/Settings/AccessManagement/userGroups/:id',
  SETTINGS_REPORTING: '/Settings/Reporting',
  SETTINGS_USER_UNBOARDING: '/Settings/UserOnboarding',

  SETTINGS_GENERAL_TAB: '/Settings/General',
  SETTINGS_GENERAL_TAB_CREATE_KNOWLEDGE_BASE: '/Settings/General/CreateKnowledgeBase',
  SETTINGS_GENERAL_TAB_EDIT_KNOWLEDGE_BASE: '/Settings/General/EditKnowledgeBase/:knowledgeBaseId',
  SETTINGS_FRONT_OFFICE_TAB: '/Settings/FrontOffice',
  SETTINGS_BACK_OFFICE_TAB: '/Settings/BackOffice',
  SETTINGS_USAGE_TAB: '/Settings/Usage',
  SETTINGS_BILLING_TAB: '/Settings/Billing',

  SETTINGS_CREATE_SERIVCE_TICKET_TYPE: '/Settings/FrontOffice/CreateServiceTicketType',
  SETTINGS_EDIT_SERIVCE_TICKET_TYPE: '/Settings/FrontOffice/EditServiceTicketType/:id',
  SETTINGS_CREATE_CHAT_WIDGET: '/Settings/FrontOffice/createChatWidget',
  SETTINGS_EDIT_CHAT_WIDGET: '/Settings/FrontOffice/editChatWidget/:id',
  SETTINGS_EDIT_CHAT_WIDGET_WITHOUT_ID: '/Settings/FrontOffice/editChatWidget',

  SETTINGS_CREATE_PARAMETER_SET: '/Settings/BackOffice/ParameterSet',
  SETTINGS_EDIT_PARAMETER_SET: '/Settings/BackOffice/ParameterSet/:id',

  SUPPORT: '/Support',
  PROFILE: '/Profile',
  PROFILE_CHANGE_PASSWORD: '/Profile/changePassword',
  PROFILE_EDIT: '/Profile/edit',
  UNAUTHORIZED: '/Unauthorized',
  FORGOT_PASSWORD: '/ForgotPassword',
  CREATE_ACCOUNT: '/CreateNewAccount',
  RESET_PASSWORD: '/ResetPassword',
  FILES: '/FileManager',
  CONVERSATION_HISTORY: '/ConversationHistory',
  PROCESS_ORCHESTRATION: '/ProcessOrchestrator',
  PROCESS_ORCHESTRATION_DETAILS: '/ProcessOrchestrator/:processId',
  PROCESS_ORCHESTRATION_EDIT: '/ProcessOrchestrator/:processId/edit',
  PROCESS_ORCHESTRATION_HISTORY: '/ProcessOrchestrator/:processId/history/:executingId',
  PROCESS_TRIGGER: '/ProcessTrigger',
  EVENT_TRIGGER_DETAILS: '/ProcessTrigger/EventTrigger',
  EVENT_TRIGGER_DETAILS_EDIT: '/ProcessTrigger/EventTrigger/:id',
  PROCESS_DATA_VISUALIZER: '/ProcessDataVisualizer',

  // MANAGERS
  AGENT_MANAGER: '/AgentManager',
  AGENT_MANAGER_DIAGRAM: '/AgentManager/:serviceTypeId',

  PROCESS_MANAGER: '/ProcessManager',
  PROCESS_MANAGER_INFO: '/flow/editor/:processId',
  PROCESS_MANAGER_SPREADSHEET: '/ProcessManager/:processId/:taskId/spreadsheet',
  TEST_MANAGER: '/test/manager',
  TEST_SUITE_DETAILS: '/test/suite/:suiteId',
  TEST_CASE_DETAILS: '/test/case/:caseId',
  TEST_GROUP_DETAILS: '/test/group/:groupId',
  TEST_EDITOR: '/test/editor',
  TEST_EDITOR_INFO: '/test/editor/:testCaseId',
  MR_MANAGER: '/Migrate/manager',
  MR_HISTORY: '/Migrate/history',
  MR_MANAGER_MAPPING_EDITOR: '/Migrate/manager/:MappingEditorId',
  CUSTOMER_MANAGER: '/Sell/CustomerManager',
  CUSTOMER_PROFILE: '/Sell/CustomerManager/:CustomerId',
  ORDER_MANAGER: '/Sell/OrderManager',
  PRODUCT_ORDER_HISTORY_DETAILS: '/Sell/OrderManager/:orderId',
  PRODUCT_OFFERINGS: '/Sell/OrderManager/ProductOfferings',
  PRODUCT_OFFERINGS_CHECKOUT: '/Sell/OrderManager/ProductOfferings/Checkout',
  PRODUCT_OFFERINGS_CONFIRMATION: '/Sell/OrderManager/ProductOfferings/Confirmation',
});

export const navLinkTitles = {
  PROJECTS: 'Projects',
  DASHBOARD: 'Dashboard',
  BOA_DASHBOARD: 'BOA Dashboard',
  BOA_DASHBOARD_LOWER_CASE: '/boaDashboard/',
  BOA_DASHBOARD_LOWER_CASE_TITLE: 'boaDashboard',
  WORKFLOW_NAME: '/:workflowName',
};

export const modifyTitlesKeysENUM = { UNDERSCORE: '_' };

export const accountDisabledKeys = {
  USER_DISABLED: 'to your user account being cancelled by the Symphona team',
  SUBSCRIPTION_AUTO_CANCELLED: 'to your user account being cancelled by the Symphona team',
};

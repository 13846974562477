import { DEFAULT_API as axios } from './AxiosInstance';

const PATH_NAME = '/user';

// get user's settings tabs
export const changePassword = async (passwordRequest) => {
  return axios.post(`${PATH_NAME}/changePassword`, passwordRequest);
};

// get data to check is user account is locked out
export const getIsAccountLocked = async () => {
  return axios.get(`${PATH_NAME}/getAccountDisableReason`);
};

export const getUsers = () => {
  return axios.get(`${PATH_NAME}/getUsers`).then((res) => res?.data);
};

export const getCurrentUser = async () => {
  return axios.get(`${PATH_NAME}/getCurrentUser`).then((res) => res?.data);
};

import routes from '../../../config/routes';

export const ROUTES_TO_SHOW_ID = [
  routes.PROCESS_MANAGER_INFO,
  routes.PROCESS_HISTORY_BULK_PREVIEW,
  routes.TICKETS_FULLVIEW,
  routes.AGENT_MANAGER_DIAGRAM,
  routes.PROCESS_ORCHESTRATION_DETAILS,
  routes.PROCESS_ORCHESTRATION_HISTORY,
  routes.FALLOUT_TICKETS_FULL_VIEW,
  routes.MR_MANAGER_MAPPING_EDITOR,
  routes.PRODUCT_ORDER_HISTORY_DETAILS,
  routes.TEST_MANAGER_INFO,
];

export const ROUTES_WITH_MODIFIED_BREADCRUMB_LABEL = [
  routes.AGENT_MANAGER_DIAGRAM,
  routes.MR_MANAGER_MAPPING_EDITOR,
  routes.CUSTOMER_PROFILE,
  routes.SETTINGS_EDIT_SERIVCE_TICKET_TYPE,
  routes.SETTINGS_GENERAL_TAB_EDIT_KNOWLEDGE_BASE,
  routes.SETTINGS_EDIT_CHAT_WIDGET,
  routes.SETTINGS_ACCESS_MANAGER_USER_DETAILS,
  routes.SETTINGS_USER_GROUPS_SINGLE_ITEM,
  routes.SETTINGS_ACCESS_MANAGER_PERMISSION_GROUP_DETAILS,
  routes.TEST_CASE_DETAILS,
  routes.TEST_SUITE_DETAILS,
  routes.TEST_GROUP_DETAILS,
  routes.TEST_EDITOR_INFO,
  routes.SETTINGS_ACCESS_MANAGER_USER_CHANGE_PASSWORD,
  routes.SETTINGS_ACCESS_MANAGER_EDIT_USER,
];

export const ROUTES_WITH_MODIFIED_PAGE_TITLE = [
  routes.TICKETS_FULLVIEW,
  routes.PROCESS_HISTORY_BULK_PREVIEW,
  routes.PROCESS_ORCHESTRATION_DETAILS,
  routes.FALLOUT_TICKETS_FULL_VIEW,
  routes.PRODUCT_OFFERINGS,
  routes.TEST_CASE_DETAILS,
  routes.TEST_SUITE_DETAILS,
  routes.TEST_GROUP_DETAILS,
];

export const ROUTES_WITH_HIDDEN_BREADCRUMB = [
  routes.DEFAULT,
  routes.BOA_DASHBOARD,
  routes.CONVERSE_DASHBOARD,
  routes.TEST_DASHBOARD,
  routes.PROFILE,
  routes.PROFILE_EDIT,
  routes.PROFILE_CHANGE_PASSWORD,
];

export const ROUTES_WITH_ADDITIONAL_MANUAL_BREADCRUMB = [
  routes.PRODUCT_OFFERINGS,
];

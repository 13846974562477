import styled from '@emotion/styled';

export const StyledTechPages = styled.div`
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

export const StyledStack = styled.pre`
  max-width: 80%;
`;

export const StyledImg = styled.img`
  width: 200px;
  max-width: 100%;
`;

/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

const AddFeatureIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M4.6 20.98a1.9 1.9 0 0 1-1.58-1.7C3 19 3 7.86 3.02 7.58A1.93 1.93 0 0 1 4.44 5.9c.15-.04.19-.04 3.17-.05 2.2 0 3.04 0 3.14.02a.94.94 0 0 1-.01 1.84c-.07.01-1.02.02-2.58.02-2.41 0-2.47 0-2.6.05a.97.97 0 0 0-.46.3c-.08.11-.16.28-.19.4-.02.09-.02 1.49-.02 5.06 0 4.36 0 4.95.03 5.06.09.35.35.6.7.7.08.02.97.03 4.96.03 5.25 0 4.96 0 5.2-.11.22-.12.43-.4.48-.67.02-.08.03-.9.03-2.69 0-2.5 0-2.58.04-2.71.1-.34.4-.59.76-.65.38-.07.8.16.98.54l.07.14v6.29l-.06.19a1.93 1.93 0 0 1-1.31 1.28l-.17.05h-5.95l-6.05-.01z" />
      <path d="M7.12 10.93c-.37-.1-.67-.4-.78-.74a1.27 1.27 0 0 1 0-.66c.12-.41.5-.72.92-.78.12-.01 1.35-.02 3.63-.02l3.46.01.14.05c.56.19.88.76.75 1.33-.1.38-.4.7-.8.8a45 45 0 0 1-3.67.04c-2.77 0-3.58 0-3.65-.03zm0 3.38c-.38-.1-.7-.42-.8-.8a1.37 1.37 0 0 1 .01-.58c.08-.29.32-.58.58-.7.27-.12.08-.12 3.86-.12 2.9 0 3.5 0 3.61.03.42.09.75.41.86.83.15.59-.25 1.23-.85 1.35-.09.02-1.15.02-3.64.02-3 0-3.53 0-3.63-.03zm.06 3.39c-.3-.06-.62-.3-.76-.58a1.18 1.18 0 0 1-.09-.8c.12-.4.46-.72.85-.8.08-.02 1.17-.03 3.61-.03 3.82 0 3.6 0 3.87.13.17.08.41.32.5.5.2.44.13.9-.2 1.25-.15.16-.4.3-.6.33-.15.03-7.04.03-7.18 0zm9.74-7.18a.97.97 0 0 1-.55-.5l-.07-.15V8.8l-.01-1.07h-.99c-.56 0-1.03 0-1.1-.02a.97.97 0 0 1-.67-.52.7.7 0 0 1-.08-.35.85.85 0 0 1 .12-.53c.07-.11.26-.3.37-.35.2-.1.28-.11 1.34-.11h1l.01-1.08V3.69l.08-.15a.94.94 0 0 1 1.7 0l.07.15v1.08l.01 1.08h1c.58 0 1.04 0 1.1.02.6.1.92.77.66 1.33a.94.94 0 0 1-.67.5c-.07.02-.49.03-1.1.03h-.99v1.03c0 1.13 0 1.16-.13 1.36-.1.14-.26.3-.4.36-.11.05-.16.06-.37.06-.16 0-.27 0-.33-.02z" />
    </SvgIcon>
  );
};

export default AddFeatureIcon;
